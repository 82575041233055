import React from "react"

const Main = () => {
  return (
    <main className="py-40 relative before:absolute before:bg-[url('/img/header-bg2.webp')] before:bg-center before:bg-cover before:bg-no-repeat before:opacity-20 before:w-full before:h-full before:top-0">
      <div className="relative text-center max-w-screen-xl my-0 mx-auto sm:text-left">
        <h1 className="text-xl  px-5 py-14 md:text-3xl md:max-w-md lg:max-w-2xl text-stone-600">
          Advocacia especializada em garantir o melhor benefício para você.
        </h1>
        <p className="p-5 text-base md:text-xl lg:text-2xl text-stone-600">
          Atendimento on-line e presencial.
        </p>
        <div className="mt-12">
          <a
            href="https://api.whatsapp.com/send?phone=5547992484880"
            target="_blank"
            rel="noreferrer"
            className="px-5 py-3 m-5 text-base md:text-lg lg:text-lg font-semibold rounded-md bg-stone-600 text-gray-100 hover:bg-stone-700 active:bg-stone-800 focus:outline-none focus:ring focus:ring-stone-400"
          >
            Agende sua consulta
          </a>
        </div>
      </div>
    </main>
  )
}

export default Main
