import React from "react"

const menuItems = [
  ["/#atuacao", "Atuação"],
  ["/#sobre", "Sobre"],
  ["/#contato", "Contato"],
]

const Header = () => {
  return (
    <header className="absolute w-full z-10">
      <div className="max-w-screen-xl my-0 mx-auto flex justify-between items-center flex-col gap-8 p-5 sm:flex-row sm:gap-0 sm:px-5 ">
        <img alt="Logotipo do site" src="/img/logo.webp" className="sm:max-w-xs lg:max-w-md"></img>
        <nav>
          <ul className="flex gap-4 sm:gap-1 flex-wrap justify-center">
            {menuItems.map(([url, label], index) => {
              const key = `menuItem${index}`
              return (
                <li key={key}>
                  <a href={url} className="text-sm py-2 px-5 border border-stone-200 focus:outline-none focus:ring focus:ring-stone-100 sm:border-none sm:block lg:text-base text-stone-600 hover:font-bold">{label}</a>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
