import React from "react"
import Header from "../components/header"
import Main from "../components/main"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Seo from "../components/seo"

export default function Home() {
  return (
    <>
      <Seo title="Home" />
      <Header />
      <Main />
      <section id="atuacao" className="max-w-screen-xl my-0 mx-auto pt-8 px-5">
        <h1 className="text-xl sm:text-2xl py-4 text-stone-600 font-bold uppercase after:my-4 after:bg-gray-200 after:h-0.5 after:w-20 after:block">
          Atuação
        </h1>
        <article className="text-base pb-4 sm:pb-0 lg:text-xl text-stone-600">
          <p className="text-justify">
            Escritório especializado em direito previdenciário, cujo o principal
            objetivo é a prestação de serviços advocatícios de forma eficiente,
            célere e comprometida. Buscando a concretização dos direitos à
            previdência e assistência dos clientes, da forma mais vantajosa e
            com maior comodidade e segurança, transformando vidas através da
            advocacia.
          </p>
        </article>
      </section>
      <section id="sobre" className="max-w-screen-xl my-0 mx-auto pt-8 px-5">
        <h1 className="text-xl sm:text-2xl py-4 text-stone-600 font-bold uppercase after:my-4 after:bg-gray-200 after:h-0.5 after:w-20 after:block">
          Sobre
        </h1>
        <div className="flex flex-col items-center sm:flex-row sm:items-start sm:space-x-14">
          <img
            alt="Foto da Raquel Montanari"
            src="/img/raquel.webp"
            className="w-full h-80 object-cover object-top sm:h-auto sm:max-w-xs xl:max-w-md"
          ></img>
          <article className="text-base lg:text-xl text-stone-600">
            <h3 className="uppercase py-4 sm:pt-0 sm:pb-10">
              Raquel Montanari <br /> OAB 55.569
            </h3>
            <p className="text-justify">
              Advogada, sócia fundadora do escritório Montanari Sociedade
              Individual de Advocacia, empreendedora e dona do seu próprio
              negócio.
            </p>
            <p className="text-justify mt-1">
              Raquel é referência na área previdenciária devido a técnica e
              expertise, possui certificação em cálculos previdenciários, é
              expert em planejamento previdenciário. Destaca-se pela aplicação
              da advocacia humanizada tendo como premissa o trabalho efetuado de
              forma personalizada, com respeito e valorização.
            </p>
            <p className="text-justify mt-1">
              Atuante na área consultiva, administrativa e contenciosa.
            </p>
          </article>
        </div>
      </section>
      <footer className="bg-stone-700">
        <div
          id="contato"
          className="max-w-screen-xl mt-8 mx-auto px-5 pt-24 pb-16 grid grid-cols-1 sm:grid-cols-2 gap-16 sm:gap-24 items-center text-stone-100 text-base"
        >
          <p>
            Atendimento on-line para todo Brasil.
            <br />
            Atendimento presencial em Itajaí-SC, Balneário Camboriú-SC e região.
          </p>
          <ul className="flex flex-col gap-4 text-base">
            <li>
              <a
                href="mailto:raquelmontanariadv@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-stone-300"
              >
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                raquelmontanariadv@gmail.com
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/advogadaraquelmontanari/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-stone-300"
              >
                <FontAwesomeIcon icon={faInstagram} className="mr-2" />
                @advogadaraquelmontanari
              </a>
            </li>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=5547992484880"
                target="_blank"
                rel="noreferrer"
                className="hover:text-stone-300"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
                (47) 9 9248-4880
              </a>
            </li>
          </ul>
          <p>© 2022 Raquel Montanari</p>
        </div>
      </footer>
    </>
  )
}
